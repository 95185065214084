import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import blogLogo from "../images/blogLogo.svg"
import blogLorem from "../images/blogLorem.svg"
import blogipsum from "../images/blogIpsum.svg"
const Blog = () => {
    return (
        <Layout>
            <Seo title="Blog" description="Checkout new posts from EviSTAMP" />
            <div>
                <section className="blog-heading">
                    <div className="blog-heading-main">
                        <article>
                            <h3>Our Blog</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate, tellus et rhoncus pretium</p>
                        </article>
                        <article>
                            <img src={blogLogo}/>
                        </article>
                    </div>
                </section>
            
                <section className="blog-lorem">
                    <div className="blog-lorem-main">
                        <article>
                            <img src={blogLorem}/>
                        </article>
                        <article>
                            <h6>Lorem</h6>
                            <h3>Ipsum dolor sit amet</h3>
                            <p className="paragraf">Dec 1, 2021</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate, tellus et rhoncus pretium, urna quam ornare diam, ac auctor purus metus at purus. Curabitur vestibulum leo neque, id hendrerit eros laoreet sit amet. Curabitur pretium diam nunc, nec ullamcorper dolor fringilla non. Nunc at purus vestibulum, viverra leo at, convallis nunc. Praesent bibendum arcu vitae magna ultrices finibus. Maecenas turpis nunc, maximus in elementum sed, vestibulum nec urna. Curabitur fermentum fermentum sem quis volutpat. Mauris luctus mattis dui ac sagittis. Vivamus libero velit, blandit vel sem quis, lacinia consectetur felis.</p>
                        </article>
                    </div>
                </section>
            
                <section className="blog-ipsum">
                    <h6>Lorem</h6>
                    <h3>Ipsum dolor sit amet</h3>
                    <div className="blog-ipsum-main">
                        <article>
                            <img src={blogipsum}/>
                            <h5>Cras vulputate, tellus et rhoncus pretium</h5>
                            <p className='paragraf'>Dec 1, 2021</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate, tellus et rhoncus pretium, urna quam ornare diam, ac auctor purus metus at purus. Curabitur vestibulum leo neque, id hendrerit eros laoreet sit amet. Curabitur pretium diam nunc, nec ullamcorper dolor fringilla non.</p>
                        </article>
                        <article>
                            <img src={blogLorem}/>
                            <h5>Cras vulputate, tellus et rhoncus pretium</h5>
                            <p className='paragraf'>Dec 1, 2021</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate, tellus et rhoncus pretium, urna quam ornare diam, ac auctor purus metus at purus. Curabitur vestibulum leo neque, id hendrerit eros laoreet sit amet. Curabitur pretium diam nunc, nec ullamcorper dolor fringilla non.</p>
                        </article>
                        <article>
                            <img src={blogipsum}/>
                            <h5>Cras vulputate, tellus et rhoncus pretium</h5>
                            <p className='paragraf'>Dec 1, 2021</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate, tellus et rhoncus pretium, urna quam ornare diam, ac auctor purus metus at purus. Curabitur vestibulum leo neque, id hendrerit eros laoreet sit amet. Curabitur pretium diam nunc, nec ullamcorper dolor fringilla non.</p>
                        </article>
                    </div>
                </section>
            
                <section className="blog-contact">
                    <h3>Contact Us</h3>
                    <div className="blog-contact-main">
                        <form>
                            <input type="text" className="name" placeholder="Enter your Name"></input>
                            <input type="email" className="email" placeholder="Enter your Email"></input>
                            <input type="text" className="message" placeholder="Add your Message"></input>
                            <div className="blog-contact-main-button">
                                <button>Send</button>
                            </div>
                        </form>

                    </div>
                </section>
            </div>
        </Layout>
    )
}
export default Blog